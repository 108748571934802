/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

const initialValue = {
  user_id: '',
  doctor_code: '',
  designation: '',
  department_id: '',
  doctor_specialities_id: '',
  is_therapist:false,
}

export const doctorinfoSlice = createSlice({
  name: 'visibleDoctorInfo',
  initialState: { visibleDoctorInfo: initialValue },
  reducers: {
    setDoctorInfo: (state, action) => {
      state.visibleDoctorInfo = action.payload
    },
    cleanUpDocor: (state) => {
      state.visibleDoctorInfo = initialValue
    },
  },
})

export const { setDoctorInfo,cleanUpDocor } = doctorinfoSlice.actions
export default doctorinfoSlice.reducer
