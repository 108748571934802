/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

const initialValue = {
    service_list: [],
}
export const BillServicesSlice = createSlice({
  name: 'service',
  initialState: { list: initialValue },
  reducers: {
    setServiceList: (state, action) => {
      state.list = action.payload
    },
  },
})

export const { setServiceList } = BillServicesSlice.actions
export default BillServicesSlice.reducer
