/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

const initialValue = {
    // prescription_id: {},
    medicine_list: [],
    isSubmitted: false,
}
export const prescriptionMedicineSlice = createSlice({
  name: 'medicine',
  initialState: { info: initialValue },
  reducers: {
    setMedicineInfo: (state, action) => {
      state.info = action.payload
    },
  },
})

export const { setMedicineInfo } = prescriptionMedicineSlice.actions
export default prescriptionMedicineSlice.reducer
