/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

const initialValue = {
    newMedicine: false,
   /*  dosage: null,
    medicine_generic_name: '', */
}
export const AddMedicineSlice = createSlice({
  name: 'medicine',
  initialState: { newMedicine: initialValue },
  reducers: {
    setMedicine: (state, action) => {
      state.newMedicine = action.payload
    },
  },
})

export const { setMedicine } = AddMedicineSlice.actions
export default AddMedicineSlice.reducer
