/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

const initialValue = {
  step: 1,
  title_id: {value: null,label:null },
  user_first_name: '',
  user_middle_name: '',
  user_last_name: '',
  dob: '',
  email: '',
  user_contact_number: null,
  country_id: {value: null,label:null },
  avatar_id: {},
  isSubmitted: false,
}
export const userInfoSlice = createSlice({
  name: 'visibleUser',
  initialState: { visibleUser: initialValue },
  reducers: {
    setUserInfo: (state, action) => {
      state.visibleUser = { ...state.visibleUser, ...action.payload }
    },
    cleanUserInfo: (state) => {
      state.visibleUser = initialValue
    },
    nextSetp: (state) => {
      state.visibleUser.step += 1
    },
    preSetp: (state) => {
      state.visibleUser.step -= 1
    },
  },
})

export const { setUserInfo, nextSetp, preSetp, cleanUserInfo } = userInfoSlice.actions
export default userInfoSlice.reducer
