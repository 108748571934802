/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

const initialValue = {
  AppointmentScreen: [],
  DoctorScreen: [],
  FinancialScreen: [],
  GeneralScreen: [],
  ItemScreen: [],
  ScheduleScreen: [],
  ServiceScreen: [],
  SettingScreen: [],
  PatientScreen:[],
  rowId:[]

}

export const roleSlice = createSlice({
  name: 'visible',
  initialState: { visible: initialValue },
  reducers: {
    setNavigation: (state, action) => {
      state.visible = action.payload
    },
    cleanupRoleScreen: (state, action) => {
      state.visible = initialValue
    },
  },
})

export const { setNavigation, cleanupRoleScreen } = roleSlice.actions
export default roleSlice.reducer
